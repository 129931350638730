import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SubpageHero from "../components/subpage-hero";
import TextImage from "../components/text-image";
import Seo from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Statut" mdxType="Seo" />
    <SubpageHero mdxType="SubpageHero">
      <h1>{`Statut fundacji`}</h1>
      <p>{`FUNDACJA ENJOY THE RIDE`}</p>
    </SubpageHero>
    <div className="etr-container">
      <div className="max-w-content-lg mx-auto">
        <h2>{`ROZDZIAŁ I. `}<br />{` POSTANOWIENIA OGÓLNE`}</h2>
        <br /> 
        <p>{`§1.`}</p>
        <p>{`Fundacja pod nazwą „Fundacja Enjoy The Ride”, zwana dalej „FUNDACJĄ” ustanowiona została przez Macieja Aniserowicza, zwanego dalej „FUNDATOREM”, aktem notarialnym sporządzonym w Kancelarii Notarialnej notariusz Ewy Lachowicz w Białymstoku przy ulicy Legionowej 3 w dniu 17 grudnia2020 za Rep. A Nr 4381/2020`}</p>
        <p>{`Fundacja działa na podstawie przepisów Ustawy z dnia 6 kwietnia 1984 r. o fundacjach, Ustawy z dnia 24 kwietnia 2003 r. o działalności pożytku publicznego i o wolontariacie, Ustawy z dnia 6 marca 2018 r. Prawo przedsiębiorców oraz niniejszego Statutu.`}</p>
        <p>{`§2.`}</p>
        <p>{`Fundacja nabywa osobowość prawną z chwilą wpisu do Krajowego Rejestru Sądowego.`}</p>
        <p>{`Czas trwania Fundacji jest nieoznaczony.`}</p>
        <p>{`§3.`}</p>
        <p>{`Siedzibą Fundacji jest miasto Białystok.`}</p>
        <p>{`§4.`}</p>
        <p>{`Fundacja może używać pieczątek z nazwą i danymi identyfikacyjnymi Fundacji.`}</p>
        <p>{`Fundacja może posiadać znak słowno-graficzny i posługiwać się nim we wszelkiej działalności związanej z osiąganiem celów statutowych.`}</p>
        <p>{`Fundacja obok nazwy w brzmieniu polsko - angielskim może posługiwać się nazwą w innych językach obcych.`}</p>
        <p>{`§ 5.`}</p>
        <p>{`W związku z realizacją zadań statutowych Fundacja może tworzyć oddziały, zakłady, filie oraz przedstawicielstwa w kraju oraz poza jej granicami.`}</p>
        <p>{`Fundacja może przystępować do spółek i innych organizacji oraz ich związków.`}</p>
        <p>{`§ 6.`}</p>
        <p>{`Fundacja może prowadzić działalność gospodarczą w kraju i za granicą dla umożliwienia realizacji swoich celów statutowych, przy czym dla osiągnięcia celów Fundacji w rozmiarach służących realizacji tych celów – Fundacja może prowadzić działalność gospodarczą samodzielnie bądź we współdziałaniu z innymi podmiotami w kraju i za granicą, zgodnie z obowiązującymi przepisami.`}</p>
        <p>{`Działalność gospodarcza prowadzona przez Fundację stanowi działalność dodatkową w stosunku do działalności mieszczącej się w sferze zadań publicznych określonych w ustawie o działalności pożytku publicznego i wolontariacie.`}</p>
        <p>{`Dochód z działalności gospodarczej Fundacji jest przeznaczony wyłącznie do realizacji celów statutowych mieszczących się w sferze zadań publicznych określonych w ustawie o działalności pożytku publicznego (celów pożytku publicznego).`}</p>
        <p>{`Fundacja może również prowadzić odpłatną działalność pożytku publicznego na zasadach określonych stosownymi przepisami dotyczącymi działalności pożytku publicznego. Przychód z tej działalności przeznaczony jest wyłącznie do realizacji celów pożytku publicznego.`}</p>
        <p>{`§ 7.`}</p>
        <p>{`Fundacja może ustanawiać certyfikaty, odznaki, medale honorowe, nagrody, wyróżnienia, a następnie przyznawać je osobom fizycznym i prawnym zasłużonym w działaniach zgodnych z celami statutowymi Fundacji.`}</p>
        <br />
        <h2>{`ROZDZIAŁ II. `}<br />{` CELE FUNDACJI I SPOSOBY ICH REALIZACJI`}</h2>
        <br /> 
        <p>{`§ 8.`}</p>
        <p>{`Fundacja została powołana w celu:`}</p>
        <ul>
          <li parentName="ul">{`działalności charytatywnej;`}</li>
          <li parentName="ul">{`działalności na rzecz rodziny, macierzyństwa, rodzicielstwa, upowszechniania i ochrony praw dziecka;`}</li>
          <li parentName="ul">{`przeciwdziałania uzależnieniom i patologiom społecznym;`}</li>
          <li parentName="ul">{`wspierania i prowadzenia inicjatyw o charakterze zdrowotnym, edukacyjnym oraz charytatywnym;`}</li>
          <li parentName="ul">{`ochrony i promocji zdrowia oraz ratowania życia dzieci;`}</li>
          <li parentName="ul">{`ochrony dzieci przed krzywdzeniem i wykluczeniem społecznym;`}</li>
          <li parentName="ul">{`wspieraniu inicjatyw na rzecz ochrony praw dziecka oraz na rzecz zapobiegania wykluczeniu społecznemu;`}</li>
          <li parentName="ul">{`pomocy dzieciom i młodzieży w trudnej sytuacji życiowej i w sytuacjach kryzysowych oraz przeciwdziałania problemom społecznym.`}</li>
        </ul>
        <p>{`§ 9.`}</p>
        <p>{`Fundacja realizuje swoje cele poprzez:`}</p>
        <ul>
          <li parentName="ul">{`działalność edukacyjną skierowaną do społeczeństwa, w szczególności do dzieci i młodzieży;`}</li>
          <li parentName="ul">{`organizowanie i prowadzenie interwencji i pomocy psychologicznej, prawnej, socjalnej i medycznej w zakresie wyznaczonym celem Fundacji;`}</li>
          <li parentName="ul">{`prowadzenie telefonicznych linii wsparcia dla osób zwracających się̨ do Fundacji o pomoc;`}</li>
          <li parentName="ul">{`wsparcie i edukację profesjonalistów świadczących pomoc psychologiczną, prawną, socjalną i medyczną adresowaną do dzieci, rodzin i osób w sytuacjach kryzysowych;`}</li>
          <li parentName="ul">{`upowszechnianie wiedzy i edukacja w zakresie przeciwdziałania krzywdzeniu dzieci, ochrona praw oraz propagowanie postaw obywatelskiej aktywności i odpowiedzialności w tym zakresie;`}</li>
          <li parentName="ul">{`programy badawcze, informacyjne, edukacyjne i wydawnicze służące zdobywaniu i upowszechnianiu wiedzy na temat praw ochrony dziecka, przeciwdziałaniu krzywdzeniu i wykluczeniu społecznemu dzieci i młodzieży;`}</li>
          <li parentName="ul">{`wspomaganie działań na rzecz zapobiegania krzywdzeniu dzieci i ochrona ich praw oraz przeciwdziałania wykluczeniu społecznemu, które są realizowane przez inne instytucje działające na rzecz dobra publicznego w różnych dziedzinach życia społecznego;`}</li>
          <li parentName="ul">{`działalność informacyjną, w tym wystawienniczą i wydawniczą;`}</li>
          <li parentName="ul">{`współtworzenie i realizację programów mających na celu edukację dzieci i młodzieży ze zdrowia psychicznego;`}</li>
          <li parentName="ul">{`propagowanie wiedzy dotyczącej zdrowia psychicznego oraz działanie na rzecz zwiększenia ich dostępności w Polsce;`}</li>
          <li parentName="ul">{`prowadzenie spotkań, szkoleń, kursów, sympozjów, konferencji, kongresów oraz wszystkich innych formach kształcenia i wsparcia;`}</li>
          <li parentName="ul">{`udział w spotkaniach, szkoleniach, kursach, sympozjach, konferencjach, kongresach oraz wszystkich innych formach kształcenia i wsparcia zawodowego o zasięgu krajowym i zagranicznym w celu pozyskania najlepszej wiedzy merytorycznej i doświadczeń praktycznych;`}</li>
          <li parentName="ul">{`współpracę (w tym poprzez członkostwo) z krajowymi, zagranicznymi oraz międzynarodowymi organizacjami lub ich związkami, prowadzącymi działalność w zakresie zbliżonym do działalności Fundacji oraz organizacjami zrzeszającymi pacjentów;`}</li>
          <li parentName="ul">{`prowadzenie witryny internetowej Fundacji służącej informowaniu o działaniach Fundacji,`}</li>
          <li parentName="ul">{`gromadzenie środków finansowych i zasobów materialnych na prowadzenie i realizację celów statutowych Fundacji.`}</li>
          <li parentName="ul">{`terapię przez sport i kulturę;`}</li>
          <li parentName="ul">{`prowadzenie mediów społecznościowych.`}</li>
        </ul>
        <br />
        <h2>{`ROZDZIAŁ III. `}<br />{` ZASADY PROWADZENIA PRZEZ FUNDACJĘ DZIAŁALNOŚCI GOSPODARCZEJ ORAZ DZIAŁALNOŚCI POŻYTKU PUBLICZNEGO`}</h2>
        <br /> 
        <p>{`§ 10.`}</p>
        <p>{`Fundacja może prowadzić działalność pożytku publicznego nieodpłatnie w zakresie następujących zadań publicznych:`}</p>
        <ul>
          <li parentName="ul">{`Działalność organizacji profesjonalnych.`}</li>
          <li parentName="ul">{`Działalność wydawnicza.`}</li>
          <li parentName="ul">{`Badania naukowe i prace rozwojowe w dziedzinie nauk społecznych i humanistycznych.`}</li>
          <li parentName="ul">{`Badania naukowe i prace rozwojowe w dziedzinie nauk przyrodniczych i technicznych.`}</li>
          <li parentName="ul">{`Działalność wspomagająca edukację.`}</li>
          <li parentName="ul">{`Pozostałe pozaszkolne formy edukacji, gdzie indziej niesklasyfikowane`}</li>
          <li parentName="ul">{`Wydawanie książek i periodyków oraz pozostała działalność wydawnicza, z wyłączeniem w zakresie oprogramowania.  `}</li>
          <li parentName="ul">{`Działalność związana z organizacją targów, wystaw i kongresów.`}</li>
          <li parentName="ul">{`Reklama.`}</li>
        </ul>
        <p>{`§11.`}</p>
        <p>{`Fundacja może prowadzić działalność pożytku publicznego odpłatnie w zakresie następujących zadań publicznych:`}</p>
        <p>{`Działalność organizatorów turystyki.`}</p>
        <p>{`Pozaszkolne formy edukacji sportowej oraz zajęć sportowych, rekreacyjnych i kulturowych.`}</p>
        <p>{`§12.`}</p>
        <p>{`Fundacja prowadzi działalność na terenie Rzeczypospolitej Polskiej, przy zachowaniu obowiązujących przepisów.`}</p>
        <p>{`Dla właściwego realizowania swoich celów Fundacja może prowadzić działalność również poza granicami Rzeczypospolitej Polskiej.`}</p>
        <p>{`Realizacja zadań statutowych Fundacji może być prowadzona we współpracy z innymi podmiotami, w tym poprzez wspieranie przez Fundację innych organizacji.  `}</p>
        <p>{`Fundacja może realizować cele statutowe także poprzez członkostwo w organizacjach zrzeszających fundacje polskie i zagraniczne, o celach statutowych zbieżnych lub tożsamych z celem Fundacji.`}</p>
        <p>{`§ 13.`}</p>
        <p>{`Fundacja może prowadzić działalność gospodarczą w kraju i za granicą, zgodnie z obowiązującymi w tym zakresie przepisami prawa, w następującym zakresie:`}</p>
        <ul>
          <li parentName="ul">{`Działalność organizacji profesjonalnych (PKD 94.12.Z)`}</li>
          <li parentName="ul">{`Działalność wydawnicza (PKD 58)`}</li>
          <li parentName="ul">{`Dzierżawa własności intelektualnej i podobnych produktów, z wyłączeniem prac chronionych prawem autorskim (PKD 77.40.Z)`}</li>
          <li parentName="ul">{`Badania naukowe i prace rozwojowe w dziedzinie nauk przyrodniczych i technicznych (PKD 72.1)`}</li>
          <li parentName="ul">{`Badania naukowe i prace rozwojowe w dziedzinie nauk społecznych i humanistycznych (PKD 72.2)`}</li>
          <li parentName="ul">{`Działalność wspomagająca edukację (PKD 85.60.Z)`}</li>
          <li parentName="ul">{`Pozostałe pozaszkolne formy edukacji, gdzie indziej niesklasyfikowane (PKD 85.59.B)`}</li>
          <li parentName="ul">{`Sprzedaż detaliczna prowadzona przez domy sprzedaży wysyłkowej lub Internet (PKD 47.91.Z)`}</li>
          <li parentName="ul">{`Działalność związana z organizacją targów, wystaw i kongresów (PKD 82.30.Z)`}</li>
          <li parentName="ul">{`Reklama ( PKD 73.1)`}</li>
        </ul>
        <p>{`Przeważającą działalnością gospodarczą Fundacji jest działalność szkoleniowa (PKD 85.59.B).`}</p>
        <p>{`§ 14.`}</p>
        <p>{`Działalność gospodarcza może być prowadzona przez wyodrębnione decyzją Fundatora jednostki organizacyjne: zakłady, wydawnictwa, biura, agencje i inne placówki. Jednostki Fundacji prowadzące działalność gospodarczą podlegają Zarządowi Fundacji.`}</p>
        <p>{`Kierującego jednostką gospodarczą Fundacji prowadzącą działalność gospodarczą powołuje Fundator.`}</p>
        <br /> 
        <h2>{`ROZDZIAŁ IV. `}<br />{` MAJĄTEK I DOCHODY FUNDACJI`}</h2>
        <p>{`§15.`}</p>
        <p>{`Majątek Fundacji stanowi jej fundusz założycielski w kwocie 10.000,00 złotych (dziesięć tysięcy złotych) przyznany przez Fundatora w oświadczeniu woli o ustanowieniu Fundacji
oraz środki finansowe, papiery wartościowe, a także rzeczy ruchome i nieruchomości i inny majątek nabyty przez Fundację w toku jej działalności.`}</p>
        <p>{`Majątek Fundacji przeznaczony jest na realizację celów statutowych Fundacji, pokrycie kosztów jej działalności i bieżącego utrzymania, jak również kosztów związanych z rozpoczęciem działalności gospodarczej przez Fundację w przyszłości.`}</p>
        <p>{`Wartość środków majątkowych przeznaczonych na działalność gospodarczą Fundacji wynosi 1.000 złotych (jeden tysiąc złotych).`}</p>
        <p>{`Majątek Fundacji jest lokowany na rachunkach i lokatach bankowych, w obligacjach, akcjach i udziałach, w nieruchomościach oraz w ruchomościach stanowiących, w rozumieniu obowiązujących przepisów, środki trwałe.`}</p>
        <p>{`Fundacja może tworzyć fundusze, w tym fundusze celowe.`}</p>
        <p>{`Z dochodów Fundacja może tworzyć szczegółowe fundusze celowe, nazwane imieniem osób, które przekazały Fundacji środki w drodze spadku lub zapisu, na których zgromadzone są środki otrzymane od w/w osób z w/w tytułów.`}</p>
        <p>{`Decyzję w sprawie utworzenia funduszy celowych i szczególnych funduszy celowych, określenia wysokości kwot przekazywanych na fundusze, a także określenia sposobu dysponowania środkami zgromadzonymi na funduszach podejmuje Zarząd, kierując się – odnośnie funduszy opisanych w § 16 ust. 1 pkt a) – wolą spadkodawców i zapisodawców.`}</p>
        <p>{`§16.`}</p>
        <p>{`Dochody Fundacji mogą pochodzić w szczególności z:`}</p>
        <ul>
          <li parentName="ul">{`darowizn, spadków, zapisów i innego nieodpłatnego przysporzenia;`}</li>
          <li parentName="ul">{`krajowych i zagranicznych dotacji i subwencji oraz grantów;`}</li>
          <li parentName="ul">{`dochodów ze zbiórek, darów i imprez publicznych, oraz aukcji organizowanych przez Fundację i na rzecz Fundacji;`}</li>
          <li parentName="ul">{`dochodów z aktywów, nieruchomości oraz praw majątkowych, w szczególności - papierów wartościowych i innych instrumentów finansowych dostępnych na rynku kapitałowym;`}</li>
          <li parentName="ul">{`dochodów z majątku Fundacji lub z majątku będącego w jej władaniu;`}</li>
          <li parentName="ul">{`odsetek i depozytów bankowych oraz papierów wartościowych;`}</li>
          <li parentName="ul">{`dochodów z tytułu udziału w zyskach osób prawnych;`}</li>
          <li parentName="ul">{`dochodów z udziałów (akcji) w spółkach handlowych, których Fundacja jest udziałowcem (akcjonariuszem);`}</li>
          <li parentName="ul">{`dochodów z odpłatnej działalności statutowej Fundacji;`}</li>
          <li parentName="ul">{`środków finansowych i innych składników majątkowych przekazanych przez Fundatorów;`}</li>
          <li parentName="ul">{`dochodów z działalności gospodarczej prowadzonej przez Fundację.`}</li>
        </ul>
        <p>{`Całość dochodów uzyskiwanych przez Fundację jest przeznaczana wyłącznie na działalność statutową.`}</p>
        <p>{`Dochody z dotacji, darowizn, spadków i zapisów mogą być użyte na realizację wszystkich celów Fundacji o ile ofiarodawcy nie postanowili inaczej (darowizny, dotacje, zapisy i spadki celowe).`}</p>
        <p>{`W sprawach przyjęcia darowizn i dziedziczenia, oświadczenia wymagane przepisami prawa składa Zarząd. W przypadku powołania Fundacji do dziedziczenia Zarząd składa oświadczenie o przyjęciu spadku z dobrodziejstwem inwentarza tylko wówczas, gdy w chwili składania tego oświadczenia jest oczywiste, że stan czynny spadku znacznie przewyższa długi spadkowe.`}</p>
        <p>{`§ 17.`}</p>
        <p>{`Fundacja odpowiada za swoje zobowiązania całym majątkiem.`}</p>
        <p>{`§ 18.`}</p>
        <p>{`Majątkiem Fundacji zarządza Zarząd Fundacji.`}</p>
        <p>{`§ 19.`}</p>
        <p>{`Fundacja nie ma prawa podejmowania działań polegających na:`}</p>
        <ul>
          <li parentName="ul">{`udzielaniu pożyczek lub zabezpieczaniu zobowiązań majątkiem organizacji w stosunku do jej członków, członków organów lub pracowników oraz osób, z którymi członkowie, członkowie organów oraz pracownicy organizacji pozostają w związku małżeńskim, we wspólnym pożyciu albo w stosunku pokrewieństwa lub powinowactwa w linii prostej, pokrewieństwa lub powinowactwa w linii bocznej do drugiego stopnia albo są związani z tytułu przysposobienia, opieki lub kurateli, zwanych dalej "osobami bliskimi",`}</li>
          <li parentName="ul">{`przekazywaniu ich majątku na rzecz ich członków, członków organów lub pracowników oraz ich osób bliskich, na zasadach innych niż w stosunku do osób trzecich, w szczególności, jeżeli przekazanie to następuje bezpłatnie lub na preferencyjnych warunkach,`}</li>
          <li parentName="ul">{`wykorzystywaniu majątku na rzecz członków, członków organów lub pracowników oraz ich osób bliskich na zasadach innych niż w stosunku do osób trzecich, chyba że to wykorzystanie bezpośrednio wynika z celu statutowego,`}</li>
          <li parentName="ul">{`zakupie towarów lub usług od podmiotów, w których uczestniczą członkowie organizacji, członkowie jej organów lub pracownicy oraz ich osób bliskich, na zasadach innych niż w stosunku do osób trzecich lub po cenach wyższych niż rynkowe`}</li>
        </ul>
        <br />
        <h2>{`ROZDZIAŁ V. `}<br />{` WŁADZE FUNDACJI`}</h2>
        <br /> 
        <p>{`§20.`}</p>
        <p>{`Władzami Fundacji jest Zarząd Fundacji.`}</p>
        <p>{`§ 21.`}</p>
        <p>{`Zarząd Fundacji, zwany dalej „Zarządem” kieruje działalnością Fundacji i reprezentuje ją na zewnątrz.`}</p>
        <p>{`Zarząd składa się z od jednego do pięciu członków, w tym Prezesa, powoływanych przez Fundatora.`}</p>
        <p>{`Członkowie Zarządu powoływani są na 5-letnią kadencję.`}</p>
        <p>{`Członkami  Zarządu Fundacji nie mogą być osoby skazane prawomocnym wyrokiem za przestępstwo umyślne ścigane z oskarżenia publicznego lub przestępstwo skarbowe.`}</p>
        <p>{`Członkiem Zarządu może być również Fundator.`}</p>
        <p>{`Członkostwo w Zarządzie wygasa na skutek śmierci, złożenia pisemnej rezygnacji z członkostwa lub odwołania. Odwołanie członka Zarządu może nastąpić w każdym czasie na mocy oświadczenia Fundatora o odwołaniu członka Zarządu. Do uzasadnionych przyczyn odwołania należą w szczególności:`}</p>
        <ul>
          <li parentName="ul">{`prowadzenie działalności uniemożliwiającej pełnienie funkcji członka Zarządu;`}</li>
          <li parentName="ul">{`choroby lub utraty sił, trwale uniemożliwiających pełnienie funkcji członka Zarządu;`}</li>
          <li parentName="ul">{`nienależytego wykonywania obowiązków członka Zarządu;`}</li>
          <li parentName="ul">{`działa na szkodę Fundacji oraz jej interesów;`}</li>
          <li parentName="ul">{`dopuszcza się rażącego niedbalstwa i niegospodarności;`}</li>
          <li parentName="ul">{`narusza przepisy ustawy o fundacjach oraz inne przepisy prawa;`}</li>
          <li parentName="ul">{`istotnego naruszenia postanowień statutu Fundacji.`}</li>
        </ul>
        <p>{`§ 22.`}</p>
        <p>{`Posiedzenia Zarządu odbywają się w miarę potrzeb, nie rzadziej jednak niż raz na sześć miesięcy.`}</p>
        <p>{`Posiedzenia Zarządu zwołuje Prezes, przesyłając informację, wszystkim członkom Zarządu, o terminie pocztą elektroniczną, a w przypadku braku takiej możliwości listem poleconym na co najmniej 10 dni przed planowanym posiedzeniem.`}</p>
        <p>{`Zarząd podejmuje decyzje na posiedzeniach w formie uchwał, przyjmowanych zwykłą większością głosów. W razie równej ilości głosów rozstrzyga głos Prezesa Zarządu. Szczegółowy tryb pracy Zarządu może być określony w Regulaminie Zarządu przyjmowanym przez Fundatora.`}</p>
        <p>{`§ 23.`}</p>
        <p>{`Członkowie Zarządu mogą otrzymywać wynagrodzenie za udział w pracach tego organu w wysokości i na zasadach określonych przez Fundatora zgodnie z jego wewnętrznymi regulacjami.`}</p>
        <p>{`§ 24.`}</p>
        <p>{`Do zadań Zarządu należy w szczególności:`}</p>
        <ul>
          <li parentName="ul">{`bieżące kierowanie działalnością Fundacji, zgodnie z jej celami, Statutem i obowiązującymi przepisami prawa;`}</li>
          <li parentName="ul">{`opracowywanie rocznych i wieloletnich planów działania oraz planów finansowych Fundacji;`}</li>
          <li parentName="ul">{`zarządzanie majątkiem i funduszami Fundacji;`}</li>
          <li parentName="ul">{`ustalanie zakresu i rodzaju działalności gospodarczej prowadzonej przez Fundację;`}</li>
          <li parentName="ul">{`kierowanie działalnością gospodarczą Fundacji;`}</li>
          <li parentName="ul">{`przyjmowanie subwencji, dotacji, darowizn, spadków i zapisów;`}</li>
          <li parentName="ul">{`uchwalanie regulaminów;`}</li>
          <li parentName="ul">{`ustalanie wielkości zatrudnienia i wysokości środków na wynagrodzenia dla pracowników Fundacji;`}</li>
          <li parentName="ul">{`występowanie z wnioskami w sprawie zmian celów i zmian Statutu Fundacji;`}</li>
          <li parentName="ul">{`występowanie z wnioskami o utworzeniu lub przystąpieniu do spółek, o połączeniu z inną fundacją lub założeniu nowej fundacji;`}</li>
          <li parentName="ul">{`występowanie z wnioskiem o likwidację Fundacji;`}</li>
          <li parentName="ul">{`podejmowanie decyzji we wszystkich sprawach niezastrzeżonych do kompetencji innych organów;`}</li>
          <li parentName="ul">{`coroczne przygotowywanie i składanie właściwemu ministrowi sprawozdania z działalności Fundacji za rok ubiegły.`}</li>
          <li parentName="ul">{`podejmowanie decyzji we wszystkich sprawach nie przekazanych do kompetencji innych organów Fundacji.`}</li>
        </ul>
        <p>{`Do podejmowanych przez Fundację przedsięwzięć Zarząd może powoływać konsultantów oraz zespoły i zlecać im wykonanie odpowiednich zadań.`}</p>
        <p>{`§25.`}</p>
        <p>{`Zarząd odpowiada za realizację celów statutowych Fundacji oraz za prawidłowośći celowość działań podejmowanych przez Fundację.`}</p>
        <p>{`§ 26.`}</p>
        <p>{`Do składania oświadczeń woli w imieniu Fundacji uprawniony jest każdy członek Zarządu samodzielnie.`}</p>
        <p>{`Zaciągnięcie przez Fundację zobowiązania przewyższającego kwotę 20.000 zł (słownie: dwadzieścia tysięcy złotych) wymaga pisemnej zgody Prezesa Zarządu.`}</p>
        <br />
        <h2>{`ROZDZIAŁ VI. `}<br />{`  POSTANOWIENIA KOŃCOWE`}</h2>
        <br /> 
        <p>{`§ 27.`}</p>
        <p>{`Statut niniejszy może być zmieniany. Decyzja w tej sprawie należy do Fundatora i jest podejmowana w formie uchwały.`}</p>
        <p>{`§ 28.`}</p>
        <p>{`Fundacja ulega likwidacji w razie wyczerpania się jej środków finansowych i majątku.`}</p>
        <p>{`Decyzję w przedmiocie likwidacji Fundacji podejmuje Fundator w drodze uchwały.`}</p>
        <p>{`Z chwilą rozwiązania Fundacji, otwarcia jej likwidacji, Zarząd przestaje działać, a Fundację reprezentują likwidatorzy.`}</p>
        <p>{`Fundator powołuje likwidatora.`}</p>
        <p>{`§ 29.`}</p>
        <p>{`Majątek pozostały po likwidacji Fundacji przekazuje się na rzecz fundacji, o celach zbieżnych do celów likwidowanej Fundacji.`}</p>
        <p>{`§ 30.`}</p>
        <p>{`Statut wchodzi w życie z dniem zarejestrowania Fundacji przez Sąd Rejonowy w Białymstoku.`}</p>
        <br /> <br /> 
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      